<template>
  <v-card class="pa-5 company show-dependency" min-width="450px">
    <h1>DEPENDENCY</h1>

    <div class="mt-10 content">
      <div class="custom-table">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{{ text }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, itemIndex) in parentList" :key="itemIndex">
              <td>
                <v-checkbox dense :input-value="childArr.includes(item)" :disabled="true" color="secondary" />
              </td>
              <td>{{ item }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["text", "parentList", "childList"],
  computed: {
    childArr() {
      if (this.childList) {
        return this.childList.split("|$|");
      }
      return [];
    },
  },
};
</script>

<style></style>
