<template>
  <v-dialog v-model="dialogShow" min-width="600px" content-class="company load-cbd customEditModal">
    <v-card class="pa-5">
      <h1>LOAD CBD</h1>

      <div class="mt-10 content">
        <div class="custom-table">
          <table>
            <thead>
              <tr>
                <th>
                  <v-checkbox
                    color="secondary"
                    :disabled="cbdList.length === 0"
                    :input-value="cbdList.every(cbd => cbd.fabricChecked && cbd.trimChecked && cbd.accessoryChecked)"
                    @change="val => allCheckClick(val)" />
                </th>
                <th>CBD TITLE</th>
                <th>FINAL COST</th>
                <th>FABRIC</th>
                <th>TRIM</th>
                <th>ACCESSORY</th>
              </tr>
            </thead>
            <tbody v-if="cbdList.length > 0">
              <tr v-for="(cbd, cbdIndex) in cbdList" :key="`tr ${cbdIndex}`">
                <td>
                  <v-checkbox
                    color="secondary"
                    :input-value="cbd.fabricChecked && cbd.trimChecked && cbd.accessoryChecked"
                    @change="val => rowCheckClick(val, cbd)" />
                </td>
                <td>{{ cbd.cbdTitle }}</td>
                <td>{{ cbd.finalPrice }}</td>
                <td>
                  <div class="align-center d-flex justify-center">
                    <v-checkbox color="secondary" v-model="cbd.fabricChecked" /> {{ `(${cbd.cntFabric})` }}
                  </div>
                </td>
                <td>
                  <div class="align-center d-flex justify-center">
                    <v-checkbox color="secondary" v-model="cbd.trimChecked" /> {{ `(${cbd.cntTrim})` }}
                  </div>
                </td>
                <td>
                  <div class="align-center d-flex justify-center">
                    <v-checkbox color="secondary" v-model="cbd.accessoryChecked" /> {{ `(${cbd.cntAccessory})` }}
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="100">
                  {{ $t("common.text.noData") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="actions d-flex justify-center">
        <v-btn
          :elevation="0"
          color="secondary"
          :loading="loadLoading"
          :disabled="cbdList.every(cbd => !cbd.fabricChecked && !cbd.trimChecked && !cbd.accessoryChecked)"
          @click="load">
          LOAD
        </v-btn>
        <v-btn :elevation="0" color="secondary" @click="dialogShow = false">CANCEL</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import codeHandler from "@/utils/codeHandler";
import { hasValue } from "@/utils/util";
export default {
  props: ["show", "bomUUID", "cbdUUID"],
  data() {
    return {
      cbdList: [],
      loadLoading: false,
    };
  },
  methods: {
    getCBDList() {
      if (!hasValue(this.cbdUUID)) return;

      this.$http
        .get("/mcl/cbd/load", {
          data: { bomUUID: this.bomUUID, cmpyUUID: this.$store.getters.getCmpyUUID, cbdUUID: this.cbdUUID },
        })
        .then(res => {
          if (res.data.status === 200) {
            if (Array.isArray(res.data.data)) {
              res.data.data.forEach(cbd => {
                cbd.fabricChecked = false;
                cbd.trimChecked = false;
                cbd.accessoryChecked = false;
              });
              this.cbdList = res.data.data;
            }
          } else codeHandler(res.data);
        });
    },
    rowCheckClick(val, cbd) {
      cbd.fabricChecked = val;
      cbd.trimChecked = val;
      cbd.accessoryChecked = val;
    },
    allCheckClick(val) {
      this.cbdList.forEach(cbd => {
        cbd.fabricChecked = val;
        cbd.trimChecked = val;
        cbd.accessoryChecked = val;
      });
    },
    load() {
      let paramText = "";
      for (let index = 0; index < this.cbdList.length; index++) {
        const cbd = this.cbdList[index];
        paramText += `${cbd.cbdUUID}:${cbd.fabricChecked ? "Y" : "N"}:${cbd.trimChecked ? "Y" : "N"}:${
          cbd.accessoryChecked ? "Y" : "N"
        }`;
        if (index !== this.cbdList.length - 1) paramText += ",";
      }

      this.loadLoading = true;
      this.$http
        .post("/mcl/cbd", { cmpyUUID: this.$store.getters.getCmpyUUID, bomUUID: this.bomUUID, str: paramText })
        .then(res => {
          if (res.data.status === 200) {
            this.$emit("load", res.data.data);
            this.dialogShow = false;
          } else codeHandler(res.data);
        })
        .finally(() => (this.loadLoading = false));
    },
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.cbdList.forEach(cbd => {
          cbd.fabricChecked = false;
          cbd.trimChecked = false;
          cbd.accessoryChecked = false;
        });
      }
    },
    cbdUUID(newVal) {
      if (hasValue(newVal)) this.getCBDList();
    },
  },
};
</script>

<style></style>
