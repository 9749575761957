<template>
  <div class="MCL">
    <div class="material-check-list">
      <div class="title d-flex">
        <a @click="goBack">
          <i class="fa-solid fa-angle-left" style="margin-right: 10px" />
        </a>
        <h1>MCL (Material Check List)</h1>
        <v-btn class="ml-2" color="secondary" v-if="editMode && !createMode" :elevation="0" @click="discardClicked">
          DISCARD
        </v-btn>
        <v-spacer />
        <v-btn v-if="!editMode" :elevation="0" :disabled="mcl.isClosed" color="secondary" @click="changeEditMode(true)">
          MODIFY
        </v-btn>
        <v-btn v-else :elevation="0" color="secondary" @click="save" :loading="saveLoading">SAVE</v-btn>
      </div>

      <div class="info-card-list d-flex">
        <!-- year/season -->
        <div class="info-card">
          <p>YEAR / SEASON</p>
          <p>{{ `${mcl.mclBomSelectDto.bomYear} / ${mcl.mclBomSelectDto.season}` }}</p>
        </div>
        <!-- buyer brand -->
        <div class="info-card">
          <p>BUYER BRAND</p>
          <p>{{ `${mcl.mclBomSelectDto.offerBrand}` }}</p>
        </div>
        <!-- gender -->
        <div class="info-card">
          <p>GENDER</p>
          <p>{{ `${mcl.mclBomSelectDto.gender}` }}</p>
        </div>
        <!-- garment type -->
        <div class="info-card">
          <p>GARMENT TYPE</p>
          <p>{{ `${mcl.mclBomSelectDto.garmentType}` }}</p>
        </div>
        <!-- fabric type -->
        <div class="info-card">
          <p>FABRIC TYPE</p>
          <p>{{ `${mcl.mclBomSelectDto.fabricType}` }}</p>
        </div>
        <!-- design no -->
        <div class="info-card">
          <p>DESIGN #</p>
          <p>{{ `${mcl.mclBomSelectDto.designNo}` }}</p>
        </div>
        <!-- style no -->
        <div class="info-card">
          <p>STYLE #</p>
          <div class="d-flex font-weight-light">
            <p
              :class="{ underline: editMode, pointer: editMode }"
              @click="
                () => {
                  if (editMode) selectStyleNumberShow = true;
                }
              ">
              {{ styleNoText }}
            </p>
            <v-btn
              v-if="editMode && !hasValue(styleNoText)"
              outlined
              small
              color="secondary"
              @click="
                () => {
                  if (!hasValue(connectedCBD)) $toast.warning('Please select CONNECTED CBD first');
                  else selectStyleNumberShow = true;
                }
              "
              :style="styleNoText.trim() !== '' ? 'margin-left:6px' : ''">
              SELECT
            </v-btn>
          </div>
        </div>
        <!-- bom title -->
        <div class="info-card">
          <p>BOM TITLE</p>
          <p>{{ `${mcl.mclBomSelectDto.coverName}` }}</p>
        </div>
        <!-- status -->
        <div class="info-card">
          <p>STATUS</p>
          <p v-if="editMode">
            {{ `${mcl.isClosed ? "CLOSED" : "OPEN"}` }}
          </p>
          <input-switch
            v-else
            :isActive="mcl.isClosed"
            activeColor="#EB5757"
            inactiveColor="#3A989E"
            :label="mcl.isClosed ? 'CLOSED' : 'OPEN'"
            @click="switchClick(mcl)" />
        </div>
      </div>

      <v-tabs v-model="tab">
        <v-tab>BASIC INFO</v-tab>
        <v-tab>PLANNING</v-tab>
      </v-tabs>
    </div>

    <v-tabs-items v-model="tab">
      <!-- Basic Info -->
      <v-tab-item>
        <div class="basic-info">
          <div class="info-table-list">
            <!-- BASIC INFORMATION -->
            <div class="info-table">
              <p>BASIC INFORMATION</p>
              <div class="custom-table">
                <table>
                  <thead>
                    <tr>
                      <th :class="{ essential: editMode }">CONNECTED CBD</th>
                      <th :class="{ essential: editMode }">MCL TITLE</th>
                      <th :class="{ essential: editMode }">MANUFACTURE COMPANY</th>
                      <th :class="{ essential: editMode }">INITIAL PCD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td :class="{ required: !hasValue(connectedCBD) }">
                        <span v-if="!editMode">{{ `${connectedCBD ? connectedCBD.cbdTitle : ""}` }}</span>
                        <v-autocomplete
                          v-else
                          dense
                          solo
                          hide-details
                          flat
                          outlined
                          :items="cbdList"
                          item-text="cbdTitle"
                          item-value="cbdUUID"
                          v-model="mcl.cbdUUID"
                          no-data-text="NO CBD HAS BEEN CLOSE STATUS"
                          @change="changeCbdUUID(mcl)">
                          <template v-slot:label>
                            <span class="font-error">SELECT CBD TITLE</span>
                          </template>
                          <template v-slot:append>
                            <v-icon> mdi-chevron-down </v-icon>
                          </template>
                        </v-autocomplete>
                      </td>
                      <td :class="{ required: !hasValue(mcl.mclName) }">
                        <span v-if="!editMode">{{ mcl.mclName }}</span>
                        <v-text-field
                          v-else
                          solo
                          flat
                          dense
                          outlined
                          hide-details
                          background-color="white"
                          v-model="mcl.mclName">
                          <template v-slot:label>
                            <span class="font-error">Input Name</span>
                          </template>
                        </v-text-field>
                      </td>
                      <td :class="{ required: !hasValue(mcl.manufactureCmpy) }">
                        <span v-if="!editMode">{{ mcl.manufactureCmpy }}</span>
                        <v-autocomplete
                          v-else
                          dense
                          solo
                          hide-details
                          flat
                          outlined
                          :items="manufactureList"
                          item-text="name"
                          item-value="cmpyUUID"
                          v-model="mcl.manufactureCmpyUUID"
                          @change="changeManufactureUUID(mcl)">
                          <template v-slot:label>
                            <span class="font-error">SET MANUFACTURE</span>
                          </template>
                          <template v-slot:append>
                            <v-icon> mdi-chevron-down </v-icon>
                          </template>
                        </v-autocomplete>
                      </td>
                      <td :class="{ required: !hasValue(mcl.initialPcd) }">
                        <span v-if="!editMode">{{ mcl.initialPcd }}</span>
                        <input-date
                          v-else
                          :open.sync="mcl.initialPcdOpen"
                          :date.sync="mcl.initialPcd"
                          :required="true" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- GARMENT PO LIST -->
            <!-- <div class="info-table">
              <p>GARMENT PO LIST</p>
              <div class="filters d-flex">
                <div class="column-filter">
                  <v-autocomplete
                    dense
                    solo
                    hide-details
                    flat
                    outlined
                    :items="{ text: 'ALL', value: 'ALL' }"
                    :value="`ALL`">
                    <template v-slot:append>
                      <v-icon> mdi-chevron-down </v-icon>
                    </template>
                  </v-autocomplete>
                </div>

                <div class="column-filter">
                  <v-autocomplete
                    dense
                    solo
                    hide-details
                    flat
                    outlined
                    :items="{ text: 'ALL', value: 'ALL' }"
                    :value="`ALL`">
                    <template v-slot:append>
                      <v-icon> mdi-chevron-down </v-icon>
                    </template>
                  </v-autocomplete>
                </div>

                <div class="search">
                  <v-text-field
                    dense
                    outlined
                    hide-details
                    placeholder="Insert Keyword to search"
                    background-color="white" />
                </div>

                <v-spacer />

                <v-btn outlined color="secondary">OVERVIEW</v-btn>
              </div>
              <div class="custom-table">
                <table>
                  <thead>
                    <tr>
                      <th>STYLE #</th>
                      <th>DEPT NAME</th>
                      <th>PO #</th>
                      <th>DCPO #</th>
                      <th>FACTORY</th>
                      <th>CNXL</th>
                      <th>ORDER</th>
                      <th>TYPE</th>
                      <th>ORDER Qty</th>
                      <th>STATUS</th>
                      <th>DOCUMENT TYPE</th>
                      <th>MODIFIED</th>
                      <th>PDF</th>
                      <th>URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>STYLE #</td>

                      <td>DEPT NAME</td>

                      <td>PO #</td>

                      <td>DCPO #</td>

                      <td>FACTORY</td>

                      <td>CNXL</td>

                      <td>ORDER</td>

                      <td>TYPE</td>

                      <td>ORDER Qty</td>

                      <td>STATUS</td>

                      <td>DOCUMENT TYPE</td>

                      <td>MODIFIED</td>

                      <td>PDF</td>

                      <td>URL</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> -->

            <!-- GARMENT ORDER QUANTITY TABLE -->
            <div class="info-table order-quantity">
              <p>ORDER QUANTITY TABLE</p>
              <p class="analytics">
                Analytics : Color ( <span class="highlight">{{ paddingZero(orderQty.colors.length) }}</span> ) x Size (
                <span class="highlight">{{ paddingZero(orderQty.sizes.length) }}</span> ) x Market (
                <span class="highlight">{{ paddingZero(orderQty.markets.length) }}</span> ) = (
                <span class="highlight">
                  {{ paddingZero(orderQty.colors.length * orderQty.sizes.length * orderQty.markets.length) }}
                </span>
                ) Items
              </p>
              <div class="custom-table">
                <table>
                  <!-- viewMode thead -->
                  <thead v-if="!editMode">
                    <tr>
                      <th rowspan="2">Market</th>
                      <th rowspan="2">Size</th>
                      <th :colspan="orderQty.colors.length">GARMENT COLOR</th>
                      <th rowspan="2">TOTAL ORDER Qty</th>
                    </tr>
                    <tr>
                      <th
                        v-for="(color, colorIndex) in orderQty.colors"
                        :key="`orderQty viewMode thead color ${colorIndex}`"
                        :class="{ required: !hasValue(color.color) }">
                        {{ color.color }}
                      </th>
                    </tr>
                  </thead>
                  <!-- editMode thead -->
                  <thead v-else>
                    <tr>
                      <th>Market</th>
                      <th class="pointer underline" @click="selectSizeGroupShow = true">Size</th>
                      <!-- color -->
                      <th
                        v-for="(color, colorIndex) in orderQty.colors"
                        :key="`orderQty editMode thead color ${colorIndex}`">
                        <div class="d-flex">
                          <v-text-field
                            :class="{ required: !hasValue(color.color) }"
                            solo
                            flat
                            dense
                            outlined
                            hide-details
                            background-color="white"
                            v-model="color.color">
                            <template v-slot:label>
                              <span class="font-error">INPUT COLOR</span>
                            </template>
                          </v-text-field>
                          <v-btn
                            v-if="orderQty.colors.length > 1"
                            color="secondary"
                            outlined
                            fab
                            x-small
                            @click="removeColor(color, colorIndex)">
                            <v-icon> mdi-minus </v-icon>
                          </v-btn>
                        </div>
                      </th>
                      <!-- addColor -->
                      <th class="pointer" @click="addColor">
                        <div class="d-flex align-center">
                          <v-btn color="secondary" outlined fab x-small>
                            <v-icon> mdi-plus </v-icon>
                          </v-btn>
                          COLOR
                        </div>
                      </th>
                      <!-- totalOrderQty -->
                      <th>TOTAL ORDER Qty</th>
                      <!-- removeSize -->
                      <th></th>
                    </tr>
                  </thead>
                  <!-- tbody -->
                  <tbody
                    v-for="(market, marketIndex) in orderQty.markets"
                    :key="`orderQty tbody market ${marketIndex}`">
                    <!-- value -->
                    <tr v-for="(size, sizeIndex) in orderQty.sizes" :key="`orderQty tbody size ${sizeIndex}`">
                      <!-- market -->
                      <td
                        v-if="sizeIndex === 0"
                        :class="{ required: !hasValue(market.market) }"
                        :rowspan="editMode && marketIndex === 0 ? orderQty.sizes.length + 1 : orderQty.sizes.length">
                        <span v-if="!editMode">{{ market.market }}</span>
                        <div v-else>
                          <v-autocomplete
                            dense
                            solo
                            hide-details
                            flat
                            outlined
                            :items="$store.state.company.company[$store.getters.getCmpyUUID].market"
                            item-text="itemName"
                            item-value="itemName"
                            v-model="market.market">
                            <template v-slot:label>
                              <span class="font-error">SELECT MARKET</span>
                            </template>
                            <template v-slot:append>
                              <v-icon> mdi-chevron-down </v-icon>
                            </template>
                          </v-autocomplete>
                          <v-btn
                            v-if="orderQty.markets.length > 1"
                            color="secondary"
                            outlined
                            fab
                            x-small
                            @click="removeMarket(market, marketIndex)">
                            <v-icon> mdi-minus </v-icon>
                          </v-btn>
                        </div>
                      </td>
                      <!-- size -->
                      <td :class="{ required: !hasValue(size.size) }">
                        <span v-if="editMode && marketIndex === 0">
                          <v-autocomplete
                            dense
                            solo
                            hide-details
                            flat
                            outlined
                            :items="sizeGroupItems"
                            @change="changeSize(size)"
                            v-model="size.size">
                            <template v-slot:label>
                              <span class="font-error">SELECT SIZE</span>
                            </template>
                            <template v-slot:append>
                              <v-icon> mdi-chevron-down </v-icon>
                            </template>
                          </v-autocomplete>
                        </span>
                        <span v-else>{{ size.size }}</span>
                      </td>
                      <!-- value -->
                      <td
                        v-for="(color, colorIndex) in orderQty.colors"
                        :key="`orderQty tbody color ${colorIndex}`"
                        :class="{ required: !hasValue(orderQty.items[marketIndex][sizeIndex][colorIndex]) }">
                        <span v-if="!editMode">{{
                          numberWithComma(orderQty.items[marketIndex][sizeIndex][colorIndex])
                        }}</span>
                        <v-text-field
                          v-else
                          solo
                          flat
                          dense
                          outlined
                          hide-details
                          background-color="white"
                          type="number"
                          v-model.number="orderQty.items[marketIndex][sizeIndex][colorIndex]">
                          <template v-slot:label>
                            <span class="font-error">Required</span>
                          </template>
                        </v-text-field>
                      </td>
                      <!-- addColor -->
                      <td v-if="editMode"></td>
                      <!-- total -->
                      <td>
                        {{
                          numberWithComma(fixedNumber(arrNumberSum(orderQty.items[marketIndex][sizeIndex]), $mathFix))
                        }}
                      </td>
                      <!-- removeSize -->
                      <td v-if="editMode">
                        <v-btn
                          v-if="marketIndex === 0 && orderQty.sizes.length > 1"
                          color="secondary"
                          outlined
                          fab
                          x-small
                          @click="removeSize(size, sizeIndex)">
                          <v-icon> mdi-minus </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <!-- addSize -->
                    <tr v-if="editMode && marketIndex === 0">
                      <td class="pointer" @click="addSize">
                        <div class="d-flex align-center">
                          <v-btn color="secondary" outlined fab x-small>
                            <v-icon> mdi-plus </v-icon>
                          </v-btn>
                          SIZE
                        </div>
                      </td>
                    </tr>
                    <!-- subtotal -->
                    <tr class="sub-total">
                      <td colspan="2">SUB TOTAL</td>
                      <!-- subTotal -->
                      <td v-for="(color, colorIndex) in orderQty.colors" :key="`orderQty tbody subTotal ${colorIndex}`">
                        {{
                          numberWithComma(fixedNumber(getSubTotal(orderQty.items[marketIndex], colorIndex), $mathFix))
                        }}
                      </td>
                      <!-- addColor -->
                      <td v-if="editMode"></td>
                      <!-- totalOrder -->
                      <td>
                        {{ numberWithComma(fixedNumber(getTotalOrder(orderQty.items[marketIndex]), $mathFix)) }}
                      </td>
                      <!-- removeSize -->
                      <td v-if="editMode"></td>
                    </tr>
                  </tbody>
                  <!-- addMarket & grand total -->
                  <tbody>
                    <!-- addMarket -->
                    <tr v-if="editMode">
                      <td class="pointer" @click="addMarket">
                        <div class="d-flex align-center">
                          <v-btn color="secondary" outlined fab x-small>
                            <v-icon> mdi-plus </v-icon>
                          </v-btn>
                          MARKET
                        </div>
                      </td>
                    </tr>
                    <!-- grandTotal -->
                    <tr class="grand-total">
                      <td colspan="2">Grand Total</td>
                      <td
                        v-for="(color, colorIndex) in orderQty.colors"
                        :key="`orderQty tbody grandTotal ${colorIndex}`">
                        {{ numberWithComma(fixedNumber(getGrandTotal(orderQty, colorIndex), $mathFix)) }}
                      </td>
                      <td v-if="editMode"></td>
                      <td>{{ numberWithComma(fixedNumber(grandTotalOrder, $mathFix)) }}</td>
                      <td v-if="editMode"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>
      <!-- PLANNING -->
      <v-tab-item>
        <div class="planning">
          <table :class="[{ editMode: editMode }, 'merge-tb-wrap tb-mcl-planning']">
            <colgroup>
              <col v-for="index in 19" :key="index" />
            </colgroup>
            <tbody>
              <tr>
                <td scope="col" colspan="100">
                  <div class="merge-div">
                    <h4 class="sub3-title">GARMENT PLANNING</h4>
                    <v-spacer />
                    <v-btn v-if="editMode" color="secondary" outlined @click="addGarmentPlan">
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                    <v-btn v-if="editMode" outlined color="secondary" @click="loadCBDClicked">LOAD CBD</v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
            <template v-for="garmentPlan in garmentPlanList">
              <!-- subtitle :: s -->
              <tbody :key="`tbody subtitle ${garmentPlan.mtrlCate}`">
                <tr>
                  <td scope="col" colspan="100">
                    <div class="merge-div">
                      <h4 class="sub4-title">{{ garmentPlan.text }} ITEM LIST</h4>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- subtitle :: e -->

              <!-- head :: s -->
              <tbody class="merge-tb-cell head" :key="`tbody head ${garmentPlan.mtrlCate}`">
                <tr>
                  <th scope="col" rowspan="2">USAGE</th>
                  <th scope="col" rowspan="2" :class="{ essential: editMode }">SUPPLIER</th>
                  <th scope="col" rowspan="2">MILL#</th>
                  <th scope="col" rowspan="2">RD#</th>
                  <th scope="col" rowspan="2" :class="{ essential: editMode }">ITEM NAME</th>
                  <th scope="col" rowspan="2" :class="{ essential: editMode }">CW/SIZE</th>
                  <th scope="col" rowspan="2" :class="{ essential: editMode }">UOM (VENDER)</th>
                  <th scope="col" rowspan="2" :class="{ essential: editMode }">NET YY</th>
                  <th scope="col" rowspan="2" :class="{ essential: editMode }">LOSS</th>
                  <th scope="col" rowspan="2">GROSS YY</th>
                  <th scope="col" rowspan="2">REQ Qty</th>
                  <th scope="col" rowspan="2">STOCK Qty</th>
                  <th scope="col" rowspan="2">ISSUED Qty</th>
                  <th scope="col" rowspan="2">BALANCE Qty</th>
                  <th scope="col" rowspan="2" :class="{ essential: editMode }">COLOR</th>
                  <th scope="col" colspan="3">DEPENDENCY</th>
                  <th scope="col" rowspan="2" v-if="!editMode">STATUS</th>
                  <th scope="col" rowspan="2" v-if="editMode"></th>
                </tr>
                <tr v-if="garmentPlan.mtrlCate === 'Fabric'">
                  <th colspan="3" class="right">GARMENT COLOR</th>
                </tr>
                <tr v-else>
                  <th>MARKET</th>
                  <th>SIZE</th>
                  <th class="right">COLOR</th>
                </tr>
              </tbody>
              <!-- head :: e -->

              <!-- body :: s -->
              <draggable
                tag="tbody"
                animation="200"
                ghostClass="ghost"
                class="merge-tb-cell body"
                filter="input"
                :preventOnFilter="false"
                :disabled="!editMode"
                :key="`tbody body ${garmentPlan.mtrlCate}`"
                v-model="
                  self()[
                    garmentPlan.mtrlCate === 'Fabric'
                      ? 'planningFabric'
                      : garmentPlan.mtrlCate === 'Trim'
                      ? 'planningTrim'
                      : 'planningAcc'
                  ]
                ">
                <tr
                  :class="{ handle: editMode }"
                  v-for="(mclPlan, mclPlanIndex) in self()[
                    garmentPlan.mtrlCate === 'Fabric'
                      ? 'planningFabric'
                      : garmentPlan.mtrlCate === 'Trim'
                      ? 'planningTrim'
                      : 'planningAcc'
                  ]"
                  :key="`row ${mclPlan.mclPlanId} ${mclPlanIndex}`">
                  <!-- useAt -->
                  <td>
                    <span v-if="!editMode">{{ mclPlan.useAt }}</span>
                    <div v-else class="d-flex align-center">
                      <i class="mx-1 fa-solid fa-ellipsis-vertical"></i>
                      <v-text-field
                        solo
                        flat
                        dense
                        outlined
                        hide-details
                        :disabled="mclPlan.mclPlanStatus === 'LOCKED'"
                        background-color="white"
                        v-model="mclPlan.useAt" />
                    </div>
                  </td>
                  <!-- spplrName -->
                  <td>
                    <span v-if="!editMode">{{ mclPlan.spplrName }}</span>
                    <span v-else>
                      {{ mclPlan.spplrName }}
                    </span>
                  </td>
                  <!-- itemNo -->
                  <td>
                    <span>{{ mclPlan.itemNo }}</span>
                    <!-- <span v-if="!editMode">{{ mclPlan.itemNo }}</span>
                        <v-text-field
                          v-else
                          solo
                          flat
                          dense
                          outlined
                          hide-details
                          :disabled="mclPlan.mclPlanStatus === 'LOCKED'"
                          background-color="white"
                          v-model="mclPlan.itemNo" /> -->
                  </td>
                  <!-- rdNo -->
                  <td>
                    <span>{{ mclPlan.rdNo }}</span>
                    <!-- <span v-if="!editMode">{{ mclPlan.rdNo }}</span>
                        <v-text-field
                          v-else
                          solo
                          flat
                          dense
                          outlined
                          hide-details
                          :disabled="mclPlan.mclPlanStatus === 'LOCKED'"
                          background-color="white"
                          v-model="mclPlan.rdNo" /> -->
                  </td>
                  <!-- itemName -->
                  <td>
                    <span v-if="!editMode">{{ mclPlan.itemName }}</span>
                    <span v-else> {{ mclPlan.itemName }} </span>
                  </td>
                  <!-- cw -->
                  <td :class="{ required: !hasValue(mclPlan.cw) }">
                    <span v-if="!editMode">{{ mclPlan.cw }}</span>
                    <v-text-field
                      v-else
                      solo
                      flat
                      dense
                      outlined
                      hide-details
                      background-color="white"
                      :disabled="mclPlan.mclPlanStatus === 'LOCKED'"
                      v-model="mclPlan.cw">
                      <template v-slot:label>
                        <span class="font-error">Required</span>
                      </template>
                    </v-text-field>
                  </td>
                  <!-- fwCwUom -->
                  <td :class="{ required: !hasValue(mclPlan.fwCwUom) }">
                    <span v-if="!editMode">{{ mclPlan.fwCwUom }}</span>
                    <v-autocomplete
                      v-else
                      dense
                      solo
                      hide-details
                      flat
                      outlined
                      :items="
                        garmentPlan.mtrlCate === $store.getters['code/getMtrlCateFabricCode']
                          ? $store.state.code.common.uomFabric
                          : $store.state.code.common.uomOther
                      "
                      :disabled="mclPlan.mclPlanStatus === 'LOCKED'"
                      item-value="itemName"
                      item-text="itemName"
                      v-model="mclPlan.fwCwUom">
                      <template v-slot:label>
                        <span class="font-error">Required</span>
                      </template>
                      <template v-slot:append>
                        <v-icon> mdi-chevron-down </v-icon>
                      </template>
                    </v-autocomplete>
                  </td>
                  <!-- netYy -->
                  <td :class="{ required: !hasValue(mclPlan.netYy) }">
                    <span v-if="!editMode">{{ numberWithComma(mclPlan.netYy) }}</span>
                    <v-text-field
                      v-else
                      solo
                      flat
                      dense
                      outlined
                      hide-details
                      background-color="white"
                      :disabled="mclPlan.mclPlanStatus === 'LOCKED'"
                      type="number"
                      v-model.number="mclPlan.netYy"
                      @keyup="setPlanGross(mclPlan)"
                      @change="setPlanGross(mclPlan)">
                      <template v-slot:label>
                        <span class="font-error">Required</span>
                      </template>
                    </v-text-field>
                  </td>
                  <!-- loss -->
                  <td :class="{ required: !hasValue(mclPlan.loss) }">
                    <span v-if="!editMode">{{ mclPlan.loss }}</span>
                    <div v-else class="d-flex align-center">
                      <v-text-field
                        solo
                        flat
                        dense
                        outlined
                        hide-details
                        background-color="white"
                        :disabled="mclPlan.mclPlanStatus === 'LOCKED'"
                        type="number"
                        v-model.number="mclPlan.loss"
                        @keyup="setPlanGross(mclPlan)"
                        @change="setPlanGross(mclPlan)">
                        <template v-slot:label>
                          <span class="font-error">Required</span>
                        </template>
                      </v-text-field>
                      %
                    </div>
                  </td>
                  <!-- grossYy -->
                  <td>
                    <span>{{ numberWithComma(fixedNumber(mclPlan.grossYy, $mathFix)) }}</span>
                  </td>
                  <!-- reqQty -->
                  <td>
                    <span>{{ numberWithComma(fixedNumber(mclPlan.reqQty, $mathFix)) }}</span>
                  </td>
                  <!-- stockQty -->
                  <td>
                    <span v-if="!editMode">{{ numberWithComma(mclPlan.stockQty) }}</span>
                    <v-text-field
                      v-else
                      solo
                      flat
                      dense
                      outlined
                      hide-details
                      background-color="white"
                      :disabled="mclPlan.mclPlanStatus === 'LOCKED'"
                      @keyup="stockQtyChange(mclPlan)"
                      @change="stockQtyChange(mclPlan)"
                      type="number"
                      v-model.number="mclPlan.stockQty">
                    </v-text-field>
                  </td>
                  <!-- issuedQty -->
                  <td>
                    <span>{{ numberWithComma(mclPlan.issuedQty) }}</span>
                  </td>
                  <!-- balanceQty -->
                  <td>
                    <span>{{ numberWithComma(fixedNumber(mclPlan.balanceQty, $mathFix)) }}</span>
                  </td>
                  <!-- color -->
                  <td :class="{ required: !hasValue(mclPlan.color) }">
                    <span v-if="!editMode">{{ mclPlan.color }}</span>
                    <v-text-field
                      v-else
                      solo
                      flat
                      dense
                      outlined
                      hide-details
                      background-color="white"
                      :disabled="mclPlan.mclPlanStatus === 'LOCKED'"
                      v-model="mclPlan.color">
                      <template v-slot:label>
                        <span class="font-error">Required</span>
                      </template>
                    </v-text-field>
                  </td>
                  <!-- viewMode dependency -->
                  <template v-if="!editMode || mclPlan.mclPlanStatus === 'LOCKED'">
                    <!-- dependency fabric -->
                    <td v-if="garmentPlan.mtrlCate === 'Fabric'" colspan="3">
                      <v-menu open-on-hover :close-on-content-click="false" top offset-x offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text x-small color="blue1" v-bind="attrs" v-on="on">
                            {{ getDependencyText("color", mclPlan.dpdcyColor) }}
                          </v-btn>
                        </template>

                        <show-dependency
                          :text="'GARMENT COLOR'"
                          :parentList="orderQty.colors.map(m => m.color)"
                          :childList="mclPlan.dpdcyColor" />
                      </v-menu>
                    </td>
                    <!-- dependency trim & accessory -->
                    <template v-else>
                      <td>
                        <v-menu open-on-hover :close-on-content-click="false" top offset-x offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text x-small color="blue1" v-bind="attrs" v-on="on">
                              {{ getDependencyText("market", mclPlan.dpdcyMarket) }}
                            </v-btn>
                          </template>

                          <show-dependency
                            :text="'MARKET'"
                            :parentList="orderQty.markets.map(m => m.market)"
                            :childList="mclPlan.dpdcyMarket" />
                        </v-menu>
                      </td>
                      <td>
                        <v-menu open-on-hover :close-on-content-click="false" top offset-x offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text x-small color="blue1" v-bind="attrs" v-on="on">
                              {{ getDependencyText("size", mclPlan.dpdcySize) }}
                            </v-btn>
                          </template>

                          <show-dependency
                            :text="'SIZE'"
                            :parentList="orderQty.sizes.map(m => m.size)"
                            :childList="mclPlan.dpdcySize" />
                        </v-menu>
                      </td>
                      <td>
                        <v-menu open-on-hover :close-on-content-click="false" top offset-x offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text x-small color="blue1" v-bind="attrs" v-on="on">
                              {{ getDependencyText("color", mclPlan.dpdcyColor) }}
                            </v-btn>
                          </template>

                          <show-dependency
                            :text="'COLOR'"
                            :parentList="orderQty.colors.map(m => m.color)"
                            :childList="mclPlan.dpdcyColor" />
                        </v-menu>
                      </td>
                    </template>
                  </template>
                  <!-- editMode dependency -->
                  <template v-else>
                    <td v-if="garmentPlan.mtrlCate === 'Fabric'" colspan="3">
                      <v-btn text x-small color="blue1" class="underline" @click="clickSetDependency(mclPlan)">
                        {{ getDependencyText("color", mclPlan.dpdcyColor) }}
                      </v-btn>
                    </td>
                    <template v-else>
                      <td>
                        <v-btn text x-small color="blue1" class="underline" @click="clickSetDependency(mclPlan)">
                          {{ getDependencyText("market", mclPlan.dpdcyMarket) }}
                        </v-btn>
                      </td>
                      <td>
                        <v-btn text x-small color="blue1" class="underline" @click="clickSetDependency(mclPlan)">
                          {{ getDependencyText("size", mclPlan.dpdcySize) }}
                        </v-btn>
                      </td>
                      <td>
                        <v-btn text x-small color="blue1" class="underline" @click="clickSetDependency(mclPlan)">
                          {{ getDependencyText("color", mclPlan.dpdcyColor) }}
                        </v-btn>
                      </td>
                    </template>
                  </template>
                  <!-- lock -->
                  <td
                    v-if="!editMode"
                    :class="[{ pointer: mcl.status !== 'CLOSED' }, 'lock d-flex']"
                    @click="lockClick(mclPlan)">
                    <template v-if="mclPlan.mclPlanStatus === 'LOCKED'">
                      <v-icon color="secondary" x-small>mdi-lock</v-icon>
                      <span :class="{ underline: mcl.status !== 'CLOSED' }">LOCKED</span>
                    </template>
                    <template v-else>
                      <v-icon color="secondary" x-small>mdi-lock-open-variant</v-icon>
                      <span :class="{ underline: mcl.status !== 'CLOSED' }">UNLOCKED</span>
                    </template>
                  </td>
                  <!-- deleteRow -->
                  <td v-if="editMode" class="d-flex">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="secondary"
                          outlined
                          fab
                          x-small
                          v-bind="attrs"
                          v-on="on"
                          @click="copyMclPlan(mclPlan)">
                          <i class="fa-solid fa-clone"></i>
                        </v-btn>
                      </template>
                      <span>Item copy</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="secondary"
                          outlined
                          fab
                          x-small
                          @click="deleteMclPlan(mclPlan)"
                          :disabled="mclPlan.mclPlanStatus === 'LOCKED'"
                          v-bind="attrs"
                          v-on="on">
                          <v-icon> mdi-minus </v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr
                  v-if="
                    self()[
                      garmentPlan.mtrlCate === 'Fabric'
                        ? 'planningFabric'
                        : garmentPlan.mtrlCate === 'Trim'
                        ? 'planningTrim'
                        : 'planningAcc'
                    ].length === 0
                  ">
                  <td colspan="100" class="no-data">{{ $t("common.text.noData") }}</td>
                </tr>
              </draggable>
              <!-- body :: e -->
            </template>
          </table>
        </div>
      </v-tab-item>
    </v-tabs-items>

    <div class="actions d-flex justify-center" v-if="editMode">
      <v-btn :elevation="0" color="secondary" @click="save" :loading="saveLoading">SAVE</v-btn>
      <v-btn :elevation="0" color="secondary" @click="changeEditMode(false)">CANCEL</v-btn>
    </div>

    <select-style-number
      :styleNoList.sync="mcl.mclStyleNoList"
      :show.sync="selectStyleNumberShow"
      :bomUUID="mcl.mclBomSelectDto.bomUUID"
      :cmpyUUID="$store.getters.getCmpyUUID"
      @addStyle="style => mcl.mclStyleNoList.push({ ...style })"
      @deleteStyle="
        style =>
          mcl.mclStyleNoList.splice(
            mcl.mclStyleNoList.findIndex(s => s.styleNo === style.styleNo),
            1,
          )
      " />

    <select-size-group :show.sync="selectSizeGroupShow" :selectSizeGroup.sync="mcl.sizeGroup" />

    <set-supplier-item :show.sync="setSupplierItemShow" :mtrlCostList="[]" @save="saveSupplierItem" />

    <create-company :show.sync="createCompanyShow" :manufactureFixed="true" @regist="registManufacture" />

    <set-dependency
      :show.sync="setDependencyProp.show"
      :colorList="orderQty.colors.map(i => i.color)"
      :sizeList="orderQty.sizes.map(i => i.size)"
      :marketList="orderQty.markets.map(i => i.market)"
      :isFabric="setDependencyProp.isFabric"
      :selectedColorList="setDependencyProp.selectedColorList"
      :selectedMarketList="setDependencyProp.selectedMarketList"
      :selectedSizeList="setDependencyProp.selectedSizeList"
      @basicInfo="() => (tab = 0)"
      @save="setDependencySave" />

    <load-cbd :show.sync="loadCbdShow" :bomUUID="mcl.mclBomSelectDto.bomUUID" :cbdUUID="mcl.cbdUUID" @load="cbdLoad" />

    <confirm :show.sync="deleteConfirmShow" :confirmButton="{ text: 'OK' }" @confirm="discard">
      Are you trying to discard this MCL? <br />
      The deleted MCL shall not be restored.
    </confirm>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import SelectStyleNumber from "@/components/company/bom/cbd/SelectStyleNumber.vue";
import SelectSizeGroup from "@/components/company/bom/mcl/SelectSizeGroup.vue";
import ShowDependency from "@/components/company/bom/mcl/ShowDependency.vue";
import SetSupplierItem from "@/components/company/bom/cbd/SetSupplierItem.vue";
import CreateCompany from "@/components/community/CreateCompany.vue";
import SetDependency from "@/components/company/bom/mcl/SetDependency.vue";
import LoadCbd from "@/components/company/bom/mcl/LoadCbd.vue";
import InputDate from "@/components/common/inputs/InputDate.vue";
import InputSwitch from "@/components/common/inputs/InputSwitch.vue";
import Confirm from "@/components/common/cards/Confirm.vue";
import codeHandler from "@/utils/codeHandler";
import draggable from "vuedraggable";
import { fixedNumber, hasValue, isDuplicate, isNumber, _deepCopy, numberWithComma, calc } from "@/utils/util";
import messageHandler from "@/utils/messageHandler";

//#region 로직 설명
/* orderQty 관련 로직
# 서버측 데이터 형식:
"mclOrderQtySelectDtoList": [
    {
        "market": "",
        "size": "",
        "colorQty": "BLACK/WHITE",
    },
    {
        "market": "USA",
        "size": "M",
        "colorQty": "1000/2000",
    }
    {
        "market": "USA",
        "size": "L",
        "colorQty": "1000/2000",
    }
    {
        "market": "TBA",
        "size": "M",
        "colorQty": "2000/4000",
    }
    {
        "market": "TBA",
        "size": "L",
        "colorQty": "2000/4000",
    }
],

# 위 데이터를 front 형식으로 바꾸면
orderQty: { //markets: ["USA", "TBA"] 로 사용하면 v-for in v-model 오류로인해 객체로 생성해야함
  "markets": [{ market: "USA" }, { market: "TBA"}],
  "sizes": [ { size: "M" }, { size: "L"}],
  "colors": [ { color: "BLACK" }, { color: "WHITE" }],
  "items": [ //markets in sizes in color
    //USA
    [
      //M
      [1000, 2000], //BLACK, WHITE
      //L
      [1000, 2000] //BLACK, WHITE
    ],
    //TBA
    [
      //M
      [2000, 4000], //BLACK, WHITE
      //L
      [2000, 4000] //BLACK, WHITE
    ]
  ]
}

핵심은 markets in sizes in color 이다.
*/
//#endregion

export default {
  components: {
    SelectStyleNumber,
    SelectSizeGroup,
    ShowDependency,
    SetSupplierItem,
    CreateCompany,
    SetDependency,
    LoadCbd,
    InputDate,
    InputSwitch,
    Confirm,
    draggable,
  },
  props: {
    fromSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: 0,
      editMode: false,
      createMode: false,
      loading: false,
      saveLoading: false,
      setSupplierItemShow: false,
      selectStyleNumberShow: false,
      selectSizeGroupShow: false,
      createCompanyShow: false,
      loadCbdShow: false,
      deleteConfirmShow: false,
      changeSpplrTarget: {},
      sizeSelectValue: "VGO_SELECT_SIZE_GROUP",
      manufactureList: [],
      cbdList: [],
      backupMCL: {},
      backupOrderQty: {},
      mcl: {
        mclBomSelectDto: {},
        mclOrderQtySelectDtoList: [],
        mclPlanningSelectDtoList: [],
        sizeGroup: "",
      },
      orderQty: {
        markets: [],
        sizes: [],
        colors: [],
        items: [],
      },
      garmentPlanList: [
        { text: "FABRIC", mtrlCate: "Fabric" },
        { text: "TRIM", mtrlCate: "Trim" },
        { text: "ACCESSORY", mtrlCate: "Accessory" },
      ],
      deleteMclPlanningList: [],
      setDependencyProp: {
        show: false,
        isFabric: false,
        selectedColorList: [],
        selectedSizeList: [],
        selectedMarketList: [],
        updateTarget: undefined,
      },
    };
  },
  methods: {
    getMclInfo() {
      this.loading = true;
      this.$http
        .get("/mcl", {
          data: {
            mclUUID: this.$store.state.currentRoute.params.mclUUID,
            cmpyUUID: this.$store.state.currentRoute.params.cmpyUUID,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            this.mcl = this.settingMCL(res.data.data);
            this.getCBDList();
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    getCBDList() {
      this.loading = true;
      this.$http
        .get("/mcl/cbd/style-no/list", {
          data: {
            bomUUID: this.mcl.mclBomSelectDto.bomUUID,
            mclUUID: this.$store.state.currentRoute.params.mclUUID,
            cmpyUUID: this.$store.state.currentRoute.params.cmpyUUID,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            this.cbdList = res.data.data;
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    settingMCL(data) {
      const mcl = { ...data, initialPcdOpen: false };
      mcl.status = data.status || "OPEN";
      mcl.isClosed = data.status === "CLOSED";

      if (Array.isArray(data.mclOrderQtySelectDtoList) && data.mclOrderQtySelectDtoList.length > 0) {
        //colors setting
        this.orderQty.colors = data.mclOrderQtySelectDtoList[0].colorQty.split("|$|").map(c => ({ color: c }));

        //markets, sizes setting
        for (let index = 0; index < data.mclOrderQtySelectDtoList.length; index++) {
          const o = data.mclOrderQtySelectDtoList[index];
          if (o.market !== "" && !this.orderQty.markets.find(m => m.market === o.market))
            this.orderQty.markets.push({ market: o.market });
          if (o.size !== "" && !this.orderQty.sizes.find(s => s.size === o.size))
            this.orderQty.sizes.push({ size: o.size });
        }

        //items setting
        for (let mi = 0; mi < this.orderQty.markets.length; mi++) {
          const ms = data.mclOrderQtySelectDtoList.filter(oq => oq.market === this.orderQty.markets[mi].market);
          const marketItem = [];

          for (let si = 0; si < this.orderQty.sizes.length; si++) {
            const cs = ms.find(m => m.size === this.orderQty.sizes[si].size).colorQty;
            const sizeItem = cs.split("|$|");
            marketItem.push(sizeItem);
          }

          this.orderQty.items.push(marketItem);
        }
      }

      return mcl;
    },
    changeEditMode(editMode, notChanged) {
      if (editMode) {
        //수정모드로 진입
        this.backupMCL = _deepCopy(this.mcl);
        this.backupOrderQty = _deepCopy(this.orderQty);
      } else {
        //읽기모드로 진입
        if (this.createMode && !notChanged) {
          //생성중이며 취소버튼 눌렀을떄
          this.$router.go(-1); //뒤로가기
        } else {
          //수정중이며 취소버튼 눌렀을때
          if (!notChanged) {
            this.mcl = _deepCopy(this.backupMCL);
            this.orderQty = _deepCopy(this.backupOrderQty);
          }
          //수정중이고 저장했을 때
          else this.getMclInfo();
        }
      }

      this.editMode = editMode;
    },
    switchClick(mcl) {
      if (mcl.status !== "CLOSED") {
        //#region mclInsertDto
        const mclInsertDto = {
          bomUUID: this.mcl.mclBomSelectDto.bomUUID,
          cmpyUUID: this.$store.getters.getCmpyUUID,
          mclUUID: this.mcl.mclUUID,
          mclName: this.mcl.mclName,
          cbdUUID: this.mcl.cbdUUID,
          manufactureCmpy: this.mcl.manufactureCmpy,
          manufactureCmpyUUID: this.mcl.manufactureCmpyUUID,
          initialPcd: this.mcl.initialPcd,
          sizeGroup: this.mcl.sizeGroup,
        };

        const checkMclInfo = this.checkMclInfo(mclInsertDto);
        if (checkMclInfo !== true) return messageHandler(checkMclInfo);
        //#endregion

        //#region mclStyleNoList
        const mclStyleNoList = this.mcl.mclStyleNoList.filter(s => s.isUse === "Y").map(s => s.styleNo);

        const checkMclStyle = this.checkMclStyle(mclStyleNoList);
        if (checkMclStyle !== true) return messageHandler(checkMclStyle);
        //#endregion

        //#region mclOrderQtyInsertDtoList
        const mclOrderQtyInsertDtoList = this.checkMclOrderQty(true, true);
        if (mclOrderQtyInsertDtoList === false) return;
        //#endregion

        //#region mclPlanningInsertDtoList
        let breakReason = "";
        if (!Array.isArray(this.mcl.mclPlanningSelectDtoList) || this.mcl.mclPlanningSelectDtoList.length < 1)
          return this.$toast.warning("There must be at least one Plan");

        for (let planIndex = 0; planIndex < this.mcl.mclPlanningSelectDtoList.length; planIndex++) {
          const p = this.mcl.mclPlanningSelectDtoList[planIndex];
          const plan = {
            mclPlanId: p.mclPlanId,
            mtrlUUID: p.mtrlUUID,
            mtrlCate: p.mtrlCate,
            mtrlType: p.mtrlType,
            mtrlCompRate: p.mtrlCompRate,
            useAt: p.useAt,
            spplrUUID: p.spplrUUID,
            spplrName: p.spplrName,
            itemNo: p.itemNo,
            rdNo: p.rdNo,
            itemName: p.itemName,
            unitPrice: p.unitPrice,
            cw: p.cw,
            fwCwUom: p.fwCwUom,
            netYy: p.netYy,
            loss: p.loss,
            grossYy: p.grossYy,
            reqQty: p.reqQty,
            stockQty: p.stockQty,
            issuedQty: p.issuedQty,
            balanceQty: p.balanceQty,
            color: p.color,
            dpdcyColor: p.dpdcyColor,
            dpdcyMarket: p.dpdcyMarket,
            dpdcySize: p.dpdcySize,
            mclPlanStatus: p.mclPlanStatus,
          };

          if (plan.mclPlanStatus !== "LOCKED") {
            breakReason = "other";
            this.$toast.warning("All Plans must be Locked");
            break;
          }

          const checkMclPlan = this.checkMclPlan(plan);
          if (checkMclPlan !== true) {
            breakReason = checkMclPlan;
            break;
          }
        }

        if (breakReason !== "") return messageHandler(breakReason);
        //#endregion
      }

      const status = mcl.status === "CLOSED" ? "OPEN" : "CLOSED";
      this.$http
        .put("/mcl/status", {
          bomUUID: this.mcl.bomUUID,
          cmpyUUID: this.$store.getters.getCmpyUUID,
          mclUUID: this.mcl.mclUUID,
          status,
        })
        .then(res => {
          if (res.data.status === 200) {
            mcl.status = status;
            mcl.isClosed = !mcl.isClosed;
          } else codeHandler(res.data);
        });
    },
    paddingZero(v) {
      return v.toString().padStart(3, 0);
    },
    arrNumberSum(arr) {
      if (!Array.isArray(arr) || arr.length < 1) return 0;
      let sum = 0;
      arr.forEach(n => {
        sum = calc(sum + (isNumber(n) ? parseFloat(n) : 0));
      });
      return sum;
    },
    getSubTotal(items, colorIndex) {
      if (!Array.isArray(items) || items.length < 1) return 0;

      let sum = 0;
      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        sum = calc(sum + (isNumber(item[colorIndex]) ? parseFloat(item[colorIndex]) : 0));
      }

      return sum;
    },
    getTotalOrder(items) {
      if (!Array.isArray(items) || items.length < 1) return 0;

      let sum = 0;
      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        sum = calc(sum + this.arrNumberSum(item));
      }

      return sum;
    },
    getGrandTotal(orderQty, colorIndex) {
      let grandTotal = 0;
      for (let marketIndex = 0; marketIndex < orderQty.markets.length; marketIndex++) {
        for (let sizeIndex = 0; sizeIndex < orderQty.sizes.length; sizeIndex++) {
          grandTotal = calc(grandTotal + parseFloat(orderQty.items[marketIndex][sizeIndex][colorIndex]));
        }
      }
      return isNumber(grandTotal) ? grandTotal : 0;
    },
    addMarket() {
      const marketItem = [];
      for (let sizeIndex = 0; sizeIndex < this.orderQty.sizes.length; sizeIndex++) {
        const sizeItem = [];
        for (let colorIndex = 0; colorIndex < this.orderQty.colors.length; colorIndex++) {
          sizeItem.push(0);
        }
        marketItem.push(sizeItem);
      }

      this.orderQty.items.push(marketItem);
      this.orderQty.markets.push({ market: "" });
    },
    removeMarket(_, marketIndex) {
      this.orderQty.markets.splice(marketIndex, 1);
      this.orderQty.items.splice(marketIndex, 1);
    },
    addSize() {
      for (let itemIndex = 0; itemIndex < this.orderQty.items.length; itemIndex++) {
        const item = this.orderQty.items[itemIndex];
        item.push(Array.from({ length: this.orderQty.colors.length }, () => 0));
      }

      this.orderQty.sizes.push({ size: "" });
    },
    removeSize(_, sizeIndex) {
      this.orderQty.sizes.splice(sizeIndex, 1);
      for (let itemIndex = 0; itemIndex < this.orderQty.items.length; itemIndex++) {
        this.orderQty.items[itemIndex].splice(sizeIndex, 1);
      }
    },
    addColor() {
      for (let itemIndex = 0; itemIndex < this.orderQty.items.length; itemIndex++) {
        for (let sizeIndex = 0; sizeIndex < this.orderQty.sizes.length; sizeIndex++) {
          this.orderQty.items[itemIndex][sizeIndex].push(0);
        }
      }

      this.orderQty.colors.push({ color: "" });
    },
    removeColor(_, colorIndex) {
      this.orderQty.colors.splice(colorIndex, 1);

      for (let itemIndex = 0; itemIndex < this.orderQty.items.length; itemIndex++) {
        for (let sizeIndex = 0; sizeIndex < this.orderQty.sizes.length; sizeIndex++) {
          this.orderQty.items[itemIndex][sizeIndex].splice(colorIndex, 1);
        }
      }
    },
    changeSize(size) {
      if (size.size === this.sizeSelectValue) {
        if (!hasValue(size.tempSizeCnt)) size.tempSizeCnt = 0;
        size.tempSizeCnt++;
        this.selectSizeGroupShow = true;
        size.size = size.tempSizeCnt % 2 === 0 ? undefined : null;
      }
    },
    save() {
      //#region mclInsertDto
      const mclInsertDto = {
        bomUUID: this.mcl.mclBomSelectDto.bomUUID,
        cmpyUUID: this.$store.getters.getCmpyUUID,
        mclUUID: this.mcl.mclUUID,
        mclName: this.mcl.mclName,
        cbdUUID: this.mcl.cbdUUID,
        manufactureCmpy: this.mcl.manufactureCmpy,
        manufactureCmpyUUID: this.mcl.manufactureCmpyUUID,
        initialPcd: this.mcl.initialPcd,
        sizeGroup: this.mcl.sizeGroup,
      };
      //#endregion

      //#region mclStyleNoList
      const mclStyleNoList = this.mcl.mclStyleNoList.filter(s => s.isUse === "Y").map(s => s.styleNo);
      //#endregion

      //#region mclOrderQtyInsertDtoList
      const mclOrderQtyInsertDtoList = this.checkMclOrderQty(true, false);
      if (mclOrderQtyInsertDtoList === false) return;
      //#endregion

      //#region mclPlanningInsertDtoList
      const mclPlanningInsertDtoList = [];
      for (let planIndex = 0; planIndex < this.mcl.mclPlanningSelectDtoList.length; planIndex++) {
        const p = this.mcl.mclPlanningSelectDtoList[planIndex];
        const plan = {
          mclPlanId: p.mclPlanId,
          mtrlUUID: p.mtrlUUID,
          mtrlCate: p.mtrlCate,
          mtrlType: p.mtrlType,
          mtrlCompRate: p.mtrlCompRate,
          useAt: p.useAt,
          spplrUUID: p.spplrUUID,
          spplrName: p.spplrName,
          itemNo: p.itemNo,
          rdNo: p.rdNo,
          itemName: p.itemName,
          unitPrice: p.unitPrice,
          cw: p.cw,
          fwCwUom: p.fwCwUom,
          netYy: p.netYy,
          loss: p.loss,
          grossYy: p.grossYy,
          reqQty: p.reqQty,
          stockQty: p.stockQty,
          issuedQty: p.issuedQty,
          balanceQty: p.balanceQty,
          color: p.color,
          dpdcyColor: p.dpdcyColor,
          dpdcyMarket: p.dpdcyMarket,
          dpdcySize: p.dpdcySize,
          priority: planIndex,
        };

        mclPlanningInsertDtoList.push(plan);
      }
      //#endregion

      this.saveLoading = true;
      this.$http
        .post("/mcl", {
          mclInsertDto,
          mclStyleNoList,
          mclOrderQtyInsertDtoList,
          mclPlanningInsertDtoList,
          deleteMclPlanningList: this.deleteMclPlanningList,
        })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.success("Successfully saved");
            if (this.createMode) {
              if (res.data.data.mclUUID) this.$router.push({ name: "MCL", params: { mclUUID: res.data.data.mclUUID } });
              else this.$router.go(-1);
            } else {
              this.changeEditMode(false, true);
            }
          } else codeHandler(res.data);
        })
        .finally(() => (this.saveLoading = false));
    },
    checkMclInfo(mclInfo = {}) {
      if (![mclInfo.mclName, mclInfo.manufactureCmpyUUID, mclInfo.initialPcd, mclInfo.cbdUUID].every(hasValue))
        return "required";
      return true;
    },
    checkMclStyle(mclStyleNoList = []) {
      if (mclStyleNoList.length < 1) return "styleRequired";
      return true;
    },
    checkMclOrderQty(frameCheck, itemCheck) {
      /*
        market 빈칸 불가, 1개 이상, 중복 불가
        size 빈칸 불가, 1개 이상, 중복 불가
        color 빈칸 불가, 1개 이상, 중복 불가
        items 빈칸 불가

        특성상 MessageHandler로 처리불가,
        검사 후 이상 있을 경우 toastMessage 띄운후, return false.
        정상일 경우 Array 반환
      */

      if (frameCheck) {
        const _markets = this.orderQty.markets.map(m => m.market);
        if (_markets.length < 1 || !_markets.every(hasValue)) {
          this.$toast.warning("Market is a required input");
          return false;
        }
        if (isDuplicate(_markets)) {
          this.$toast.warning("Market cannot be duplicated");
          return false;
        }

        const _sizes = this.orderQty.sizes.map(s => s.size);
        if (_sizes.length < 1 || !_sizes.every(hasValue)) {
          this.$toast.warning("Size is a required input");
          return false;
        }
        if (isDuplicate(_sizes)) {
          this.$toast.warning("Size cannot be duplicated");
          return false;
        }

        const _colors = this.orderQty.colors.map(c => c.color);
        if (_colors.length < 1 || !_colors.every(hasValue)) {
          this.$toast.warning("Color is a required input");
          return false;
        }
        if (isDuplicate(_colors)) {
          this.$toast.warning("Color cannot be duplicated");
          return false;
        }
      }

      const mclOrderQtyInsertDtoList = [];
      mclOrderQtyInsertDtoList.push({
        market: "",
        size: "",
        colorQty: this.orderQty.colors.map(c => c.color).join("|$|"),
      });

      let breakReason = "";
      outer: for (let marketIndex = 0; marketIndex < this.orderQty.markets.length; marketIndex++) {
        const market = this.orderQty.markets[marketIndex];
        for (let sizeIndex = 0; sizeIndex < this.orderQty.sizes.length; sizeIndex++) {
          const size = this.orderQty.sizes[sizeIndex];

          if (itemCheck) {
            if (!this.orderQty.items[marketIndex][sizeIndex].every(hasValue)) {
              breakReason = "quantityRequired";
              break outer;
            }
          }

          mclOrderQtyInsertDtoList.push({
            market: market.market,
            size: size.size,
            colorQty: this.orderQty.items[marketIndex][sizeIndex].join("|$|"),
          });
        }
      }
      if (breakReason === "quantityRequired") {
        this.$toast.warning("Quantity is a required input");
        return false;
      }

      return mclOrderQtyInsertDtoList;
    },
    checkMclPlan(plan = {}) {
      if (![plan.mtrlUUID, plan.spplrUUID, plan.itemName].every(hasValue)) {
        return "error";
      }
      if (![plan.cw, plan.fwCwUom, plan.netYy, plan.loss, plan.color].every(hasValue)) {
        return "required";
      }
      if (![plan.netYy, plan.loss, plan.stockQty].every(val => isNumber(val, true))) {
        return "number";
      }
      return true;
    },
    getDependencyText(type, item) {
      const notText = "N/A";
      const allText = "ALL";
      const someText = "SELECTIVE";

      if (!hasValue(item) || item.trim() === "") return notText;
      let childArr = item.split("|$|");

      let parentArr;
      switch (type) {
        case "market":
          parentArr = this.orderQty.markets.map(m => m.market);
          break;
        case "size":
          parentArr = this.orderQty.sizes.map(m => m.size);
          break;
        case "color":
          parentArr = this.orderQty.colors.map(m => m.color);
          break;
        default:
          break;
      }

      if (Array.isArray(parentArr)) {
        let eq = 0;
        for (let index = 0; index < parentArr.length; index++) {
          const parent = parentArr[index];
          if (childArr.includes(parent)) eq++;
        }

        if (eq === 0) return notText;
        if (eq === parentArr.length) return allText;
        if (eq > 0 && eq < parentArr.length) return someText;
      }

      return "";
    },
    lockClick(mclPlan) {
      if (this.mcl.status === "CLOSED") return;

      const checkMclPlan = this.checkMclPlan(mclPlan);
      if (checkMclPlan !== true) return messageHandler(checkMclPlan);

      const mclPlanStatus = mclPlan.mclPlanStatus === "UNLOCKED" ? "LOCKED" : "UNLOCKED";
      this.$http
        .put("/mcl/planning/status", {
          mclPlanId: mclPlan.mclPlanId,
          mclUUID: this.mcl.mclUUID,
          mclPlanStatus,
          cmpyUUID: this.$store.getters.getCmpyUUID,
        })
        .then(res => {
          if (res.data.status === 200) {
            mclPlan.mclPlanStatus = mclPlanStatus;
          } else codeHandler(res.data);
        });
    },
    getManufactures() {
      this.$http.get("company/list").then(res => {
        if (res.data.status === 200)
          this.manufactureList = [
            { cmpyUUID: "ADD_MANUFACTURE", name: "ADD A NEW MANUFACTURE COMPANY" },
            { divider: true },
            ...res.data.data?.filter(d => d.cmpyType === "MANUFACTURER"),
          ];
        else codeHandler(res.data);
      });
    },
    changeManufactureUUID(mcl) {
      if (mcl.manufactureCmpyUUID === "ADD_MANUFACTURE") {
        //tempSpplrUUIDCnt는, 계속 undefined로만 바꾸면 change를 인식을 못해서 생성
        if (!hasValue(mcl.tempMnftUUIDCnt)) mcl.tempMnftUUIDCnt = 0;
        mcl.tempMnftUUIDCnt++;
        mcl.manufactureCmpyUUID = mcl.tempMnftUUIDCnt % 2 === 0 ? undefined : null;
        mcl.manufactureCmpy = "";
        this.createCompanyShow = true;
        this.changeSpplrTarget = mcl;
      } else {
        mcl.manufactureCmpy = this.manufactureList.find(m => m.cmpyUUID === mcl.manufactureCmpyUUID).name;
      }
    },
    registManufacture(company) {
      this.manufactureList.push(company);
      this.changeSpplrTarget.manufactureCmpyUUID = company.cmpyUUID;
      this.changeSpplrTarget.manufactureCmpy = company.name;
    },
    addGarmentPlan() {
      this.setSupplierItemShow = true;
    },
    saveSupplierItem(items) {
      if (!Array.isArray(items)) return;
      items.forEach(i => {
        const newPlan = {
          mtrlUUID: i.mtrlUUID,
          mtrlCate: i.mtrlCate,
          mtrlType: i.mtrlType,
          mtrlCompRate: i.itemDetail,
          useAt: "",
          spplrUUID: i.spplrUUID,
          spplrName: i.spplrName,
          itemNo: i.itemNo || "",
          rdNo: i.rdNo || "",
          itemName: i.itemName,
          cw: "",
          fwCwUom: "",
          netYy: 0,
          loss: 0,
          grossYy: 0,
          dpdcyQty: this.grandTotalOrder || 0,
          reqQty: 0,
          stockQty: 0,
          issuedQty: 0,
          balanceQty: 0,
          color: "",
          dpdcyColor: "",
          dpdcySize: "",
          dpdcyMarket: "",
          mclPlanStatus: "UNLOCKED",
        };
        this.setPlanReqQty(newPlan);
        this.mcl.mclPlanningSelectDtoList.push(newPlan);
      });
    },
    setPlanGross(garmentPlan) {
      if (!garmentPlan) return;
      const grossYy = calc(
        parseFloat(garmentPlan.netYy) + parseFloat(garmentPlan.netYy) * (parseFloat(garmentPlan.loss) * 0.01),
      );
      garmentPlan.grossYy = isNumber(grossYy) ? grossYy : 0;

      this.setPlanReqQty(garmentPlan);
    },
    setPlanReqQty(garmentPlan) {
      if (!hasValue(garmentPlan)) return;
      const reqQty = calc((parseFloat(garmentPlan.grossYy) || 0) * (parseFloat(garmentPlan.dpdcyQty) || 0));
      garmentPlan.reqQty = isNumber(reqQty) ? reqQty : 0;

      this.setPlanBalanceQty(garmentPlan);
    },
    setPlanBalanceQty(garmentPlan) {
      if (!hasValue(garmentPlan)) return;
      const balance = calc(
        (parseFloat(garmentPlan.reqQty) || 0) -
          (parseFloat(garmentPlan.stockQty) || 0) -
          (parseFloat(garmentPlan.issuedQty) || 0),
      );
      garmentPlan.balanceQty = isNumber(balance) ? balance : 0;
    },
    clickSetDependency(mclPlan) {
      this.setDependencyProp.isFabric = mclPlan.mtrlCate === "Fabric";
      this.setDependencyProp.selectedColorList = mclPlan.dpdcyColor?.split("|$|") || [];
      this.setDependencyProp.selectedMarketList = mclPlan.dpdcyMarket?.split("|$|") || [];
      this.setDependencyProp.selectedSizeList = mclPlan.dpdcySize?.split("|$|") || [];
      this.setDependencyProp.show = true;
      this.setDependencyProp.updateTarget = mclPlan;
    },
    setDependencySave({ dpdcyColor, dpdcySize, dpdcyMarket }) {
      this.setDependencyProp.updateTarget.dpdcyColor = dpdcyColor.join("|$|");
      this.setDependencyProp.updateTarget.dpdcySize = dpdcySize.join("|$|");
      this.setDependencyProp.updateTarget.dpdcyMarket = dpdcyMarket.join("|$|");

      const selectMarketIndex = [];
      for (let marketIndex = 0; marketIndex < this.orderQty.markets.length; marketIndex++) {
        const market = this.orderQty.markets[marketIndex].market;
        if (dpdcyMarket.length === 0) selectMarketIndex.push(marketIndex);
        else if (dpdcyMarket.includes(market)) selectMarketIndex.push(marketIndex);
      }

      const selectSizeIndex = [];
      for (let sizeIndex = 0; sizeIndex < this.orderQty.sizes.length; sizeIndex++) {
        const size = this.orderQty.sizes[sizeIndex].size;
        if (dpdcySize.length === 0) selectSizeIndex.push(sizeIndex);
        else if (dpdcySize.includes(size)) selectSizeIndex.push(sizeIndex);
      }

      const selectColorIndex = [];
      for (let colorIndex = 0; colorIndex < this.orderQty.colors.length; colorIndex++) {
        const color = this.orderQty.colors[colorIndex].color;
        if (dpdcyColor.length === 0) selectColorIndex.push(colorIndex);
        else if (dpdcyColor.includes(color)) selectColorIndex.push(colorIndex);
      }

      let sum = 0;
      for (let marketIndex = 0; marketIndex < this.orderQty.items.length; marketIndex++) {
        if (!selectMarketIndex.includes(marketIndex)) continue;
        const markets = this.orderQty.items[marketIndex];
        for (let sizeIndex = 0; sizeIndex < markets.length; sizeIndex++) {
          if (!selectSizeIndex.includes(sizeIndex)) continue;
          const sizes = markets[sizeIndex];
          for (let colorIndex = 0; colorIndex < sizes.length; colorIndex++) {
            if (selectColorIndex.includes(colorIndex)) sum = calc(sum + (parseFloat(sizes[colorIndex]) || 0));
          }
        }
      }

      this.setDependencyProp.updateTarget.dpdcyQty = sum;
      this.setPlanReqQty(this.setDependencyProp.updateTarget);
    },
    deleteMclPlan(mclPlan) {
      if (hasValue(mclPlan.mclPlanId)) {
        this.deleteMclPlanningList.push(mclPlan.mclPlanId);
      }

      this.mcl.mclPlanningSelectDtoList.splice(
        this.mcl.mclPlanningSelectDtoList.findIndex(c => c === mclPlan),
        1,
      );
    },
    cbdLoad(list) {
      if (!Array.isArray(list) || list.length < 1) return;

      for (let index = 0; index < list.length; index++) {
        const i = list[index];
        const newPlan = {
          mtrlUUID: i.mtrlUUID,
          mtrlCate: i.mtrlCate,
          mtrlType: i.mtrlType,
          mtrlCompRate: i.mtrlCompRate,
          useAt: i.useAt || "",
          spplrUUID: i.spplrUUID,
          spplrName: i.spplrName,
          itemNo: i.itemNo || "",
          rdNo: i.rdNo || "",
          itemName: i.itemName,
          unitPrice: i.unitPrice || 0,
          cw: i.cw,
          fwCwUom: i.fwCwUom,
          netYy: i.netYy,
          loss: i.loss,
          grossYy: i.grossYy || 0,
          dpdcyQty: this.grandTotalOrder || 0,
          reqQty: 0,
          stockQty: 0,
          issuedQty: 0,
          balanceQty: 0,
          color: "",
          dpdcyColor: "",
          dpdcySize: "",
          dpdcyMarket: "",
          mclPlanStatus: "UNLOCKED",
        };
        this.setPlanReqQty(newPlan);
        this.mcl.mclPlanningSelectDtoList.push(newPlan);
      }
    },
    stockQtyChange(mclPlan) {
      this.setPlanBalanceQty(mclPlan);
    },
    changeCbdUUID(mcl) {
      if (!this.connectedCBD) return this.$toast.error("Unknown Error");

      mcl.mclStyleNoList.forEach(styleNo => {
        styleNo.isUse = "N";
      });

      const usedStyleList = this.connectedCBD.styleNo.split("|$|");
      usedStyleList.forEach(styleNo => {
        const style = mcl.mclStyleNoList.find(s => s.styleNo === styleNo);
        if (style) style.isUse = "Y";
      });
    },
    loadCBDClicked() {
      if (!hasValue(this.mcl.cbdUUID)) return this.$toast.warning("Please select CONNECTED CBD first");
      this.loadCbdShow = true;
    },
    discardClicked() {
      this.deleteConfirmShow = true;
    },
    discard() {
      this.loading = true;
      this.$http
        .patch("/mcl", {
          cmpyUUID: this.$store.getters.getCmpyUUID,
          mclUUID: this.$store.state.currentRoute.params.mclUUID,
        })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.success("Discarded successfully.");
            this.$router.push({ name: "BOM" });
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    goBack() {
      if (
        this.fromSearch === true &&
        this.$store.state.user.bomSearchOption.targetUUID === this.$store.state.currentRoute.params.mclUUID
      )
        this.$router.push({ name: "BOM", query: { loadSearchOption: true } });
      else {
        this.$router.push({ name: "BOM", query: { bomUUID: this.mcl.bomUUID } });
      }
    },
    self() {
      return this;
    },
    copyMclPlan(mclPlan) {
      if (mclPlan) {
        const index = this.mcl.mclPlanningSelectDtoList.findIndex(i => i === mclPlan);
        if (index !== -1) {
          const newPlan = _deepCopy(mclPlan);
          newPlan.mclPlanId = undefined;
          newPlan.mclPlanStatus = "UNLOCKED";
          this.mcl.mclPlanningSelectDtoList.splice(index + 1, 0, newPlan);
        }
      }
    },
    numberWithComma,
    fixedNumber,
    hasValue,
    calc,
  },
  computed: {
    styleNoText() {
      let text = "";
      const filterdList = this.mcl.mclStyleNoList?.filter(s => s.isUse === "Y");
      filterdList?.forEach((s, i) => {
        text += s.styleNo;
        if (i !== filterdList.length - 1) text += ", ";
      });
      return text;
    },
    sizeGroupItems() {
      const defaultItem = [
        {
          text: "SELECT SIZE GROUP",
          value: this.sizeSelectValue,
        },
      ];

      if (!hasValue(this.mcl.sizeGroup)) return defaultItem;

      let groupItems = this.$store.state.company.company[this.$store.getters.getCmpyUUID].sizeGroup
        ?.find(gs => gs.itemName === this.mcl.sizeGroup)
        ?.itemDesc?.split("|$|")
        ?.map(g => ({ text: g, value: g }));

      return groupItems
        ? [...groupItems, { divider: true }, { text: this.mcl.sizeGroup, value: this.sizeSelectValue }]
        : defaultItem;
    },
    grandTotalOrder() {
      let sum = 0;
      for (let marketIndex = 0; marketIndex < this.orderQty.markets.length; marketIndex++) {
        for (let sizeIndex = 0; sizeIndex < this.orderQty.sizes.length; sizeIndex++) {
          for (let colorIndex = 0; colorIndex < this.orderQty.colors.length; colorIndex++) {
            sum = calc(sum + parseFloat(this.orderQty.items[marketIndex][sizeIndex][colorIndex]));
          }
        }
      }
      return isNumber(sum) ? sum : 0;
    },
    connectedCBD() {
      return this.cbdList.find(c => c.cbdUUID === this.mcl.cbdUUID);
    },
    planningFabric: {
      get() {
        return this.mcl.mclPlanningSelectDtoList.filter(p => p.mtrlCate === "Fabric");
      },
      set(value) {
        this.mcl.mclPlanningSelectDtoList = value.concat(this.planningTrim).concat(this.planningAcc);
      },
    },
    planningTrim: {
      get() {
        return this.mcl.mclPlanningSelectDtoList.filter(p => p.mtrlCate === "Trim");
      },
      set(value) {
        this.mcl.mclPlanningSelectDtoList = this.planningFabric.concat(value).concat(this.planningAcc);
      },
    },
    planningAcc: {
      get() {
        return this.mcl.mclPlanningSelectDtoList.filter(p => p.mtrlCate === "Accessory");
      },
      set(value) {
        this.mcl.mclPlanningSelectDtoList = this.planningFabric.concat(this.planningTrim).concat(value);
      },
    },
  },
  watch: {
    orderQty: {
      handler() {
        //orderQty가 수정되면 모든 Plan 내 setDependency를 초기화한다
        if (this.editMode && Array.isArray(this.mcl.mclPlanningSelectDtoList)) {
          this.mcl.mclPlanningSelectDtoList.forEach(plan => {
            plan.dpdcyMarket = "";
            plan.dpdcySize = "";
            plan.dpdcyColor = "";
            plan.dpdcyQty = this.grandTotalOrder || 0;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.getManufactures();
    this.$watch(
      () => this.$route.params,
      () => {
        this.editMode = false;
        this.createMode = false;
        this.getMclInfo();
      },
    );

    if (this.$store.state.currentRoute.params.mclUUID === "create") {
      const bomInfo = this.$store.state.currentRoute.params.bomInfo;
      if (!hasValue(bomInfo)) {
        this.$toast.error("It's an abnormal approach.");
        return this.$router.go(-1);
      }

      const mcl = {};
      mcl.mclName = "";
      mcl.manufactureCmpy = "";
      mcl.manufactureCmpyUUID = "";
      mcl.initialPcd = "";
      mcl.initialPcdOpen = false;
      mcl.status = "OPEN";
      mcl.isClosed = false;
      mcl.mclBomSelectDto = bomInfo;
      mcl.mclStyleNoList = bomInfo.bomStyleNoList.map(s => ({ isUse: "N", styleNo: s.styleNo }));
      mcl.mclOrderQtySelectDtoList = [];
      mcl.mclPlanningSelectDtoList = [];
      mcl.sizeGroup = "";

      this.addMarket();
      this.addSize();
      this.addColor();

      this.mcl = mcl;
      this.editMode = true;
      this.createMode = true;

      this.getCBDList();
    } else this.getMclInfo();
  },
};
</script>
