<template>
  <v-dialog v-model="dialogShow" min-width="300px" content-class="company select-size-group customEditModal">
    <v-card class="pa-5">
      <h1>SELECT SIZE GROUP</h1>
      <div class="mt-10 content">
        <div class="search">
          <v-text-field
            solo
            flat
            dense
            outlined
            hide-details
            background-color="white"
            append-icon="mdi-magnify"
            label="Insert Keywords to search"
            v-model="searchText" />
        </div>

        <div class="size-group-list">
          <v-radio-group v-model="selectedSizeGroup">
            <v-radio
              color="secondary"
              v-for="sizeGroup in sizeGroupList"
              :key="sizeGroup.itemName"
              :label="sizeGroup.itemName"
              :value="sizeGroup.itemName" />
          </v-radio-group>
        </div>
      </div>
    </v-card>
    <div class="actions d-flex justify-center">
      <v-btn :elevation="0" color="secondary" @click="save"> OK </v-btn>
      <v-btn :elevation="0" color="secondary" @click="dialogShow = false"> CANCEL </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { hasValue } from "@/utils/util";
export default {
  props: ["show", "selectSizeGroup"],
  data() {
    return {
      searchText: "",
      selectedSizeGroup: "",
    };
  },
  methods: {
    save() {
      if (!hasValue(this.selectedSizeGroup)) return this.$toast.warning("Please choose the select size group");
      this.$emit("update:selectSizeGroup", this.selectedSizeGroup);
      this.dialogShow = false;
    },
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
        this.searchText = "";
      },
    },
    sizeGroupList() {
      const sizeGroupList = this.$store.state.company.company[this.$store.getters.getCmpyUUID].sizeGroup;
      if (!sizeGroupList) return [];

      const sizeGroup = sizeGroupList.filter(gs => {
        return gs.itemName?.toLowerCase().includes(this.searchText.toLowerCase());
      });

      return sizeGroup || [];
    },
  },
  watch: {
    show(newValue) {
      if (newValue) this.selectedSizeGroup = this.selectSizeGroup;
    },
  },
  created() {
    this.selectedSizeGroup = this.selectSizeGroup;
  },
};
</script>

<style></style>
