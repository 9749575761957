<template>
  <v-dialog v-model="dialogShow" min-width="300px" content-class="company set-dependency customEditModal">
    <v-card class="pa-5">
      <h1>SET DEPENDENCY</h1>

      <div class="mt-10 content">
        <div class="d-flex">
          <!-- market -->
          <div class="custom-table" v-if="!isFabric">
            <table>
              <thead>
                <tr>
                  <th><v-checkbox v-model="marketAllCheck" color="secondary" :disabled="!hasMarketItem" /></th>
                  <th>MARKET</th>
                </tr>
              </thead>
              <tbody v-if="hasMarketItem">
                <tr v-for="(market, marketIndex) in markets" :key="`market ${marketIndex}`">
                  <td><v-checkbox v-model="market.checked" color="secondary" /></td>
                  <td>{{ market.market }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="2">
                    <div class="empty-size">
                      설정된 사이즈값이 없습니다.<br />
                      BASIC INFO > ORDER QUANTITY에서<br />
                      먼저 사이즈를 설정해야 합니다.<br />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- size -->
          <div class="custom-table" v-if="!isFabric">
            <table>
              <thead>
                <tr>
                  <th><v-checkbox v-model="sizeAllCheck" color="secondary" :disabled="!hasSizeItem" /></th>
                  <th>SIZE</th>
                </tr>
              </thead>
              <tbody v-if="hasSizeItem">
                <tr v-for="(size, sizeIndex) in sizes" :key="`size ${sizeIndex}`">
                  <td><v-checkbox v-model="size.checked" color="secondary" /></td>
                  <td>{{ size.size }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="2">
                    <div class="empty-size">
                      설정된 사이즈값이 없습니다.<br />
                      BASIC INFO > ORDER QUANTITY에서<br />
                      먼저 사이즈를 설정해야 합니다.<br />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- color -->
          <div class="custom-table">
            <table>
              <thead>
                <tr>
                  <th>
                    <v-checkbox v-model="colorAllCheck" color="secondary" :disabled="!hasColorItem" />
                  </th>
                  <th>GARMENT COLOR</th>
                </tr>
              </thead>
              <tbody v-if="hasColorItem">
                <tr v-for="(color, colorIndex) in colors" :key="`color ${colorIndex}`">
                  <td>
                    <v-checkbox v-model="color.checked" color="secondary" />
                  </td>
                  <td>{{ color.color }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="2">
                    <div class="empty-size">
                      설정된 사이즈값이 없습니다.<br />
                      BASIC INFO > ORDER QUANTITY에서<br />
                      먼저 사이즈를 설정해야 합니다.<br />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="actions d-flex justify-center">
        <v-btn
          :elevation="0"
          color="secondary"
          v-if="!hasColorItem && !hasSizeItem && !hasMarketItem"
          @click="basicInfo">
          BASIC INFO
        </v-btn>
        <v-btn :elevation="0" color="secondary" v-else @click="save">SAVE</v-btn>
        <v-btn :elevation="0" color="secondary" @click="dialogShow = false">CANCEL</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { hasValue } from "@/utils/util";
export default {
  props: [
    "show",
    "isFabric",
    "colorList",
    "sizeList",
    "marketList",
    "selectedColorList",
    "selectedSizeList",
    "selectedMarketList",
  ],
  data() {
    return {
      colors: [],
      sizes: [],
      markets: [],
    };
  },
  methods: {
    init() {
      this.colors = this.colorList.map(i => ({ checked: this.selectedColorList.includes(i), color: i }));
      this.sizes = this.sizeList.map(i => ({ checked: this.selectedSizeList.includes(i), size: i }));
      this.markets = this.marketList.map(i => ({ checked: this.selectedMarketList.includes(i), market: i }));
    },
    basicInfo() {
      this.$emit("basicInfo");
      this.dialogShow = false;
    },
    save() {
      const results = {
        dpdcyColor: this.colors.filter(i => i.checked).map(i => i.color),
        dpdcySize: this.sizes.filter(i => i.checked).map(i => i.size),
        dpdcyMarket: this.markets.filter(i => i.checked).map(i => i.market),
      };
      this.$emit("save", results);
      this.dialogShow = false;
    },
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    hasColorItem() {
      if (!Array.isArray(this.colorList) || this.colorList.length < 1) return false;
      if (this.colorList.length === 1 && !hasValue(this.colorList[0])) return false;
      return true;
    },
    hasSizeItem() {
      if (!Array.isArray(this.sizeList) || this.sizeList.length < 1) return false;
      if (this.sizeList.length === 1 && !hasValue(this.sizeList[0])) return false;
      return true;
    },
    hasMarketItem() {
      if (!Array.isArray(this.marketList) || this.marketList.length < 1) return false;
      if (this.marketList.length === 1 && !hasValue(this.marketList[0])) return false;
      return true;
    },
    colorAllCheck: {
      get() {
        return this.colors.every(i => i.checked);
      },
      set(value) {
        this.colors.forEach(i => (i.checked = value));
      },
    },
    sizeAllCheck: {
      get() {
        return this.sizes.every(i => i.checked);
      },
      set(value) {
        this.sizes.forEach(i => (i.checked = value));
      },
    },
    marketAllCheck: {
      get() {
        return this.markets.every(i => i.checked);
      },
      set(value) {
        this.markets.forEach(i => (i.checked = value));
      },
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        //화면이 보여지도록 설정되면
        this.init();
      }
    },
  },
};
</script>

<style></style>
